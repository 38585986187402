import "core-js/modules/es.number.constructor.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "report-detail"
  }, [_c("a-form", {
    attrs: {
      form: _vm.form,
      labelCol: {
        span: 7
      },
      wrapperCol: {
        span: 17
      },
      labelAlign: "left"
    }
  }, [_c("div", {
    staticClass: "box"
  }, [_c("a-row", {
    staticClass: "box-title px-20"
  }, [_c("span", {
    staticClass: "title"
  }, [_vm._v("基础信息")])]), _c("div", {
    staticClass: "list-item px-20"
  }, [_c("a-form-item", {
    attrs: {
      label: _vm.isChannel ? "被举报群UID" : "被举报人UID"
    }
  }, [_c("a-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.form.bereportedid,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "bereportedid", $$v);
      },
      expression: "form.bereportedid"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: _vm.isChannel ? "被举报群名称" : "被举报人昵称",
      labelAlign: "right"
    }
  }, [_c("a-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.form.bereportednickname,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "bereportednickname", $$v);
      },
      expression: "form.bereportednickname"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "举报人UID"
    }
  }, [_c("a-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.form.reportuid,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "reportuid", $$v);
      },
      expression: "form.reportuid"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "举报人昵称",
      labelAlign: "right"
    }
  }, [_c("a-input", {
    attrs: {
      disabled: ""
    },
    model: {
      value: _vm.form.reportnickname,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "reportnickname", $$v);
      },
      expression: "form.reportnickname"
    }
  })], 1), _c("a-form-item", {
    attrs: {
      label: "举报时间(GMT+8)"
    }
  }, [_c("a-input", {
    attrs: {
      disabled: "",
      value: _vm.$moment(Number(_vm.form.createtime)).utcOffset(8).format("YYYY-MM-DD HH:mm:ss")
    }
  })], 1)], 1)], 1), _c("div", {
    staticClass: "box mt-20"
  }, [_c("a-row", {
    staticClass: "box-title px-20"
  }, [_c("span", {
    staticClass: "title"
  }, [_vm._v("详情")])]), _c("div", {
    staticClass: "px-20 mt-20"
  }, [_c("a-form-item", {
    attrs: {
      label: "举报类型"
    }
  }, [_c("a-input", {
    attrs: {
      disabled: "",
      value: _vm.itemReportType(_vm.form.bereportedtype)
    }
  })], 1), _c("a-form-item", {
    staticStyle: {
      width: "660px"
    },
    attrs: {
      label: "举报原因"
    }
  }, [_c("a-textarea", {
    staticStyle: {
      width: "100% !important"
    },
    attrs: {
      "auto-size": {
        minRows: 2,
        maxRows: 6
      },
      disabled: ""
    },
    model: {
      value: _vm.form.reportedreason,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "reportedreason", $$v);
      },
      expression: "form.reportedreason"
    }
  })], 1), _c("a-form-item", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      label: "图片"
    }
  }, [_c("div", {
    staticClass: "flex-wrapper"
  }, _vm._l(_vm.form.reportedimgurl, function (item, index) {
    return _c("img", {
      key: index,
      staticClass: "img",
      attrs: {
        src: item
      },
      on: {
        click: function click($event) {
          return _vm.showPreview(item);
        }
      }
    });
  }), 0)])], 1)], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !(_vm.isView && _vm.isPass == 1),
      expression: "!(isView && isPass == 1)"
    }],
    staticClass: "box mt-20"
  }, [_c("a-row", {
    staticClass: "box-title px-20"
  }, [_c("span", {
    staticClass: "title"
  }, [_vm._v("审核")])]), _c("div", {
    staticClass: "px-20 mt-20 mb-20"
  }, [_c("a-radio-group", {
    attrs: {
      disabled: _vm.isView
    },
    model: {
      value: _vm.isPass,
      callback: function callback($$v) {
        _vm.isPass = $$v;
      },
      expression: "isPass"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 2
    }
  }, [_vm._v("通过")]), _c("a-radio", {
    attrs: {
      value: 3
    }
  }, [_vm._v("驳回")])], 1)], 1)], 1), _c("a-button", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isView,
      expression: "!isView"
    }],
    staticClass: "mt-20",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.checkHandler
    }
  }, [_vm._v("提交")])], 1), _c("PreviewPic", {
    attrs: {
      isShowPic: _vm.previewVisible,
      previewImage: _vm.previewImage
    },
    on: {
      closePreviewpic: function closePreviewpic($event) {
        _vm.previewVisible = false;
      }
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };