import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.function.name.js";
import { IMApi } from '@/api';
export default {
  data: function data() {
    return {
      form: {
        reportedimgurl: []
      },
      isPass: 2,
      previewVisible: false,
      previewImage: ""
    };
  },
  props: {
    isChannel: {
      type: Boolean,
      default: false
    },
    itemId: {
      type: String | Number,
      default: {}
    },
    isView: {
      type: Boolean,
      default: false
    },
    reportType: {
      type: String | Number,
      default: []
    }
  },
  mounted: function mounted() {
    this.getDetail();
  },
  methods: {
    showPreview: function showPreview(imgUrl) {
      this.previewImage = imgUrl;
      this.previewVisible = true;
    },
    getDetail: function getDetail() {
      var _this = this;
      IMApi.reportDetail({
        id: this.itemId
      }).then(function (res) {
        _this.form = res || {
          reportedimgurl: []
        };
        if (!_this.isView) _this.isPass = 2;
        if (_this.isView) _this.isPass = res.reportedstatus;
      });
    },
    itemReportType: function itemReportType(id) {
      if (id === undefined || id === '') return;
      var items = this.reportType.filter(function (val) {
        return val.id === id;
      });
      return items[0].name;
    },
    checkHandler: function checkHandler() {
      var _this2 = this;
      IMApi.reportAudit({
        id: this.itemId,
        reportedstatus: this.isPass
      }).then(function (res) {
        _this2.$emit('close');
      });
    }
  }
};